<template>
    <div>
        <b-row>
            <b-col lg="12" xl="12">
                <b-row>
                    <!-- carousel pictures -->
                    <b-col class="p-3" cols="12" lg="12" md="12" sm="12" xl="6">
                        <div>
                            <b-carousel
                                id="product-carousel"
                                v-model="slide"
                                :interval="0"
                                background="#ababab"
                                class="img-container"
                                controls
                                fade
                                indicators
                                @sliding-start="onSlideStart"
                                @sliding-end="onSlideEnd"
                            >
                                <b-carousel-slide
                                    v-for="carousel in getCarouselPictures"
                                    :img-src="carousel.src"
                                    class="provider-carousel"
                                ></b-carousel-slide>
                            </b-carousel>
                            <!--                                    :caption="carousel.caption"-->


                            <!--                            <b-carousel-->
                            <!--                                id="product-carousel"-->
                            <!--                                v-model="slide"-->
                            <!--                                :interval="0"-->
                            <!--                                controls-->
                            <!--                                indicators-->
                            <!--                                background="#ababab"-->
                            <!--                                class="img-container"-->
                            <!--                                @sliding-start="onSlideStart"-->
                            <!--                                @sliding-end="onSlideEnd"-->
                            <!--                                fade-->
                            <!--                            >-->
                            <!--                                <b-carousel-slide class="product-carousel" caption="First slide"-->
                            <!--                                                  img-src="../../../assets/images/temp/carousel/1.png"></b-carousel-slide>-->
                            <!--                                <b-carousel-slide class="product-carousel" caption="1:1"-->
                            <!--                                                  img-src="../../../assets/images/temp/carousel/1-1-ratio_Morgan-Caddell-2191-scaled.jpg"></b-carousel-slide>-->
                            <!--                                <b-carousel-slide class="product-carousel" caption="2:3"-->
                            <!--                                                  img-src="../../../assets/images/temp/carousel/2-3-ratio_Morgan-Caddell-9304-2048x1366.jpg"></b-carousel-slide>-->
                            <!--                                <b-carousel-slide class="product-carousel" caption="4:3 scaled"-->
                            <!--                                                  img-src="../../../assets/images/temp/carousel/4-3-ratio_Morgan-Caddell-3955.jpg"></b-carousel-slide>-->
                            <!--                                <b-carousel-slide class="product-carousel" caption="4:3"-->
                            <!--                                                  img-src="../../../assets/images/temp/carousel/4-3-ratio_Morgan-Caddell-4023-scaled.jpg"></b-carousel-slide>-->
                            <!--                            </b-carousel>-->
                        </div>
                    </b-col>
                    <!-- product -->
                    <b-col class="p-3 bg-white" cols="12" lg="12" md="12" sm="12" xl="6">
                        <!-- name -->
                        <h1>{{getName}}</h1>
                        <!-- supplier -->
                        <h4 class="text-primary">{{getOrganisation.name}}</h4>
                        <!-- sale -->
                        <h4 class="mb-2 mt-4">{{$tc('trade.product.packaging', 2)}}:</h4>
                        <b-list-group class="products-packages text-center" flush>
                            <b-list-group-item v-for="(sale, index) in getSale" style="padding: 8px 0!important;">
                                <!-- quantity, packaging -->
                                <b-row align-v="center" no-gutters>
                                    <b-col class="" cols="3">
                                        <b>{{sale.quantity}} {{sale.unit}}</b>
                                        <br>
                                        {{sale.packaging}}
                                    </b-col>
                                    <!-- price, pricer per unit -->
                                    <b-col class="pl-1 pr-1" cols="2">
                                        <b>{{sale.rpt_string}}</b>
                                        <br>
                                        <div class="small">{{sale.ppi_string}}</div>
                                    </b-col>
                                    <!-- buyer quantity -->
                                    <b-col class="pl-2" cols="auto">
                                        <b-form-spinbutton
                                            :id="'spin-button-buyer_quantity' + sale.index"
                                            v-model="buyerQuantities[index]"
                                            class="" inline
                                            min="1"
                                            size="sm"
                                        ></b-form-spinbutton>
                                    </b-col>
                                    <!-- buyer price -->
                                    <b-col class="pl-2 text-primary" cols="3">
                                        <b>{{getBuyerPrice[index].price_string}}</b>
                                    </b-col>
                                    <!-- cart button -->
                                    <b-col cols="1">
                                        <b-overlay
                                            :opacity="overlay.opacity"
                                            :show="overlay.visible"
                                            class="d-inline-block"
                                            rounded
                                            spinner-small
                                            spinner-variant="secondary"
                                        >
                                            <b-button
                                                :disabled="isCartDisabled"
                                                :title="isCartDisabled ? $t('cart.noLogin_message') : null"
                                                :variant="getCartVariant"
                                                @click="addToCart(index)"
                                            >
                                                <b-icon-basket/>
                                            </b-button>
                                        </b-overlay>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>
                <!-- row tabs -->
                <b-row>
                    <b-col>
                        <b-card class="mb-2" no-body>
                            <b-tabs card fill pills>
                                <!-- tab description -->
                                <b-tab class="product-tabs">
                                    <template #title>
                                        <b-icon-flower3 class="mr-1"></b-icon-flower3>
                                        {{$t('trade.product.tab.description')}}
                                    </template>
                                    <p class="p-0" v-html="getDescription"></p>
                                </b-tab>
                                <!-- tab ingredients -->
                                <b-tab class="product-tabs">
                                    <template #title>
                                        <b-icon-card-list class="mr-1"></b-icon-card-list>
                                        {{$t('trade.product.tab.ingredient')}}
                                    </template>
                                    <p class="p-0" v-html="getIngredient"></p>
                                </b-tab>
                                <!-- tab requirements -->
                                <b-tab class="product-tabs">
                                    <template #title>
                                        <b-icon-exclamation-circle class="mr-1"></b-icon-exclamation-circle>
                                        {{$t('trade.product.tab.requirement')}}
                                    </template>
                                    <p class="p-0" v-html="getRequirementString"></p>
                                </b-tab>
                                <!-- tab certificates -->
                                <b-tab class="product-tabs">
                                    <template #title>
                                        <b-icon-file-medical class="mr-1"></b-icon-file-medical>
                                        {{$t('trade.product.tab.certificate')}}
                                    </template>
                                    <b-table
                                        v-if="getCertificateItems.length > 0"
                                        id="main-table"
                                        :fields="tableCertificates.fields"
                                        :items="getCertificateItems"
                                        bordered
                                        head-row-variant="primary"
                                        hover
                                        outlined
                                        sticky-header="330px"
                                        table-class="text-center"
                                    ></b-table>
                                </b-tab>
                                <!-- tab supplier -->
                                <!--                                <b-tab class="product-tabs">
                                                                    <template #title>
                                                                        <b-icon-house class="mr-1"></b-icon-house>
                                                                        {{$t('trade.product.tab.producer')}}
                                                                    </template>
                                                                    <p class="p-0">Kmetija</p>
                                                                </b-tab>-->
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "ModalProduct",
    components: {},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        id: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            record: {},
            data: {},
            slide: 0,
            sliding: null,
            buyerQuantities: [],
            tableCertificates: {
                items: [],
                fields: [
                    {
                        key: 'organisation',
                        label: this.$t('organisation.certificate_organisation'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'name',
                        label: this.$t('organisation.certificate_name'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'number',
                        label: this.$t('organisation.certificate_number'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'expires',
                        label: this.$t('organisation.certificate_expires'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },
                    {
                        key: 'tag_id',
                        label: this.$t('form.type'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },

                ],
            },
            errors: {
                addToCart: false,
            },
        }
    },
    mounted() {
        // <-- api calls -->
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        // <-- base -->
        baseSelect: function () {
            this.showOverlay(true);
            this.axios.get(this.uri.uri2 + `/${this.id}`)
                .then((response) => {
                    this.$set(this, 'data', response.data.records);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        addToCart(index) {
            this.errors.addToCart = false;
            this.showOverlay(true);
            let cartRecord = {
                product_id: this.data.id,
                quantity: this.buyerQuantities[index],
                sale: index,
            };
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'cartRecord': cartRecord,
            };
            // <-- axios -->
            this.axios.post(this.uri.carts, data, this.__config)
                .then((response) => {
                    // <-- cart count -->
                    this.$root.mx_cartCount = response.data;
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // errors = errors.response.data;
                    // this.errors.message = errors;
                    this.errors.addToCart = true;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        getCarouselPictures() {
            if (this.__isNull(this.data.pictures_order) || this.data.pictures_order.length === 0 || this.data.pictures_order === undefined) {
                return [
                    {
                        caption: null,
                        src: this.__getDefaultImage
                    }
                ];
            }
            let carousel = [];
            // <-- src -->
            let caption = null;
            this.data.pictures_order.forEach((value, index) => {
                if (value.publish) {
                    this.data.pictures.find((picture) => {
                        if (picture.key === value.key) {
                            caption = picture.meta.name;
                            // <-- locale -->
                            if (picture.locale !== null && picture.locale[this.$i18n.locale] !== undefined) {
                                if (!this.__isNull(picture.locale[this.$i18n.locale].name)) {
                                    caption = picture.locale[this.$i18n.locale].name;
                                }
                            }
                            carousel.push(
                                {
                                    caption: caption,
                                    src: this.__getImageSrc(picture.image.src)
                                }
                            );
                        }
                    });
                }
            });
            return carousel;
        },
        getName() {
            if (this.__isNull(this.data) || this.data.meta === undefined) {
                return null;
            }
            // <--  -->
            let _record = this.data;
            // <--  -->
            let name = _record.meta.name;
            let localeTemp = this.__getJsonLocaleValue(_record.locale, 'name')
            name = localeTemp === null ? name : localeTemp;
            // <--  -->
            return name;
        },
        getOrganisation() {
            if (this.__isNull(this.data.organisation)) {
                return {
                    name: null,
                    href: 0,
                };
            }
            // <--  -->
            let org = this.data.organisation;
            let name = this.__isNull(org.name.name) ? org.name.full_name : org.name.name;
            // <--  -->
            return {
                name: name,
                href: org.id,
            }
        },
        getSale() {
            if (this.data.sale === undefined) {
                return [];
            }
            // <--  -->
            let sales = this.data.sale;
            let array = [];
            let quantity, unit, packaging, rpt_string, currency, ppi_string;
            let localeTemp;
            sales.forEach((sale, index) => {
                // <-- add data to BuyerQuantities -->
                if (sales.length > this.buyerQuantities.length) {
                    this.buyerQuantities.push(1);
                }
                // <--  -->
                currency = this.__getTenantCurrency;
                quantity = this.__getDecimalLocale(sale.quantity);
                rpt_string = this.__getDecimalLocale(sale.rpt) + ' ' + currency;

                unit = sale.unit.meta.mark;
                localeTemp = this.__getJsonLocaleValue(sale.unit.locale, 'mark');
                unit = localeTemp === null ? mark : localeTemp;

                packaging = sale.packaging.meta.name;
                localeTemp = this.__getJsonLocaleValue(sale.packaging.locale, 'name');
                packaging = localeTemp === null ? name : localeTemp;

                ppi_string = this.__getDecimalLocale(sale.ppi);
                ppi_string += ' ' + currency + '/' + unit;
                // <--  -->
                array.push(
                    {
                        index: index,
                        quantity: quantity,
                        unit: unit,
                        packaging: packaging,
                        ppi: sale.ppi,
                        ppi_string: ppi_string,
                        // rp: rp,
                        rpt: sale.rpt,
                        rpt_string: rpt_string,
                    }
                );
            });
            // <--  -->
            return array;
        },
        getBuyerPrice() {
            let array = [];
            let sale = this.getSale;
            let price;
            this.buyerQuantities.forEach((quantity, index) => {
                price = quantity * sale[index].rpt;
                array.push(
                    {
                        price: price,
                        price_string: this.__getCurrencyLocale(price),
                    }
                );
            });
            // <--  -->
            return array;
        },
        getDescription() {
            if (this.data.locale === undefined) {
                return null;
            }
            // <--  -->
            let description = null;
            let localeTemp;
            localeTemp = this.__getJsonLocaleValue(this.data.locale, 'description');
            description = localeTemp === null ? description : localeTemp;
            // <--  -->
            return description;
        },
        getIngredient() {
            if (this.data.locale === undefined) {
                return null;
            }
            // <--  -->
            let ingredients = null;
            let localeTemp;
            localeTemp = this.__getJsonLocaleValue(this.data.locale, 'ingredients');
            ingredients = localeTemp === null ? ingredients : localeTemp;
            // <--  -->
            return ingredients;
        },
        getRequirement() {
            if (this.__isNull(this.data.requirement)) {
                return null;
            }
            // <--  -->
            let requirement = this.data.requirement.meta.name;
            let localeTemp;
            localeTemp = this.__getJsonLocaleValue(this.data.requirement.locale, 'name');
            requirement = localeTemp === null ? requirement : localeTemp;
            // <--  -->
            return requirement;
        },
        getRequirementString() {
            let string = '';
            // <-- requirement -->
            let requirement = this.getRequirement;
            string += requirement === null ? '' : this.$t('trade.product.temperature_control') + ': ' + requirement;
            // <-- content -->
            if (!this.__isNull(this.data.content)) {
                let content = this.data.content;
                // <-- alcohol_containing -->
                if (content.alcohol_containing !== undefined) {
                    string += string.length > 0 ? '<br>' : '';
                    string += content.alcohol_containing === null ? '' : this.$t('trade.product.alcohol_containing') + ' ' + this.__getDecimalLocale(content.alcohol_containing) + ' ' + this.$tc('trade.product.alcohol_containing_unit', 1);
                }
                // <-- legal_alcohol_age -->
                if (content.legal_alcohol_age !== undefined && content.legal_alcohol_age) {
                    string += string.length > 0 ? '<br>' : '';
                    string += this.$tc('trade.product.minimal_legal_age', 1);
                }
            }
            // <--  -->
            return string.length === 0 ? null : string;
        },
        getCertificateItems() {
            if (this.__isNull(this.data.certificates)) {
                return [];
            }
            // <-- set items -->
            let array = [];
            let tag;
            this.data.certificates.forEach(item => {
                if (item.tag !== null) {
                    // <-- tag -->
                    tag = item.tag.meta.name;
                    // <-- locale -->
                    if (item.tag.locale[this.$i18n.locale] !== undefined) {
                        if (!this.__isNull(item.tag.locale[this.$i18n.locale].name)) {
                            tag = item.tag.locale[this.$i18n.locale].name;
                        }
                    }
                    array.push(
                        {
                            // key: this.__createUniqueKey(index),
                            // index: index,
                            // id: item.id,
                            // state_id: item.state_id,
                            organisation: item.org_name,
                            number: item.number,
                            name: item.name,
                            expires: item.expires,
                            tag_id: tag,
                        }
                    );
                }
            });
            // <--  -->
            return array;
        },
        // <-- cart -->
        isCartDisabled() {
            return !this.$root.mx_isLogin;
        },
        getCartVariant() {
            if (this.isCartDisabled) {
                return 'light';
            }
            return this.errors.addToCart ? 'danger' : 'secondary';
        }


    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
