<template>
    <div>
        <b-row>
            <b-col lg="12" xl="12">
                <b-row>
                    <!-- carousel pictures -->
                    <b-col class="p-3" cols="12" lg="12" md="12" sm="12" xl="6">
                        <div>
                            <b-carousel
                                id="product-carousel"
                                v-model="slide"
                                :interval="0"
                                background="#ababab"
                                class="img-container"
                                fade
                                indicators
                                controls
                                @sliding-start="onSlideStart"
                                @sliding-end="onSlideEnd"
                            >
                                <b-carousel-slide
                                    v-for="carousel in getCarouselPictures"
                                    :img-src="carousel.src"
                                    class="provider-carousel"
                                ></b-carousel-slide>
<!--                                    :caption="carousel.caption"-->
                            </b-carousel>
                        </div>
                    </b-col>
                    <!--  Organisation  -->
                    <b-col class="p-3 bg-white" cols="12" lg="12" md="12" sm="12" xl="6">
                        <h1>{{getOrgName}}</h1>
                        <h4 class="text-primary">{{getOrgGroup}}</h4>
                        <!--  data  -->
                        <b-row>
                            <b-col cols="4" lg="3" md="4" sm="4">
                                <b-card-text class="mb-2 mt-3">{{$t('organisation.region')}}:</b-card-text>
                            </b-col>
                            <b-col cols="8" lg="9" md="8" sm="8">
                                <b-card-text class="mb-2 mt-3 text-secondary">{{getRegion}}</b-card-text>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4" lg="3" md="4" sm="4">
                                <b-card-text class="mb-2 mt-3">{{$t('organisation.town')}}:</b-card-text>
                            </b-col>
                            <b-col cols="8" lg="9" md="8" sm="8">
                                <b-card-text class="mb-2 mt-3 text-secondary"><b>{{getCity}}</b></b-card-text>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4" lg="3" md="4" sm="4">
                                <b-card-text class="mb-2 mt-3">{{$t('organisation.farm_acreage')}}:</b-card-text>
                            </b-col>
                            <b-col cols="8" lg="9" md="8" sm="8">
                                <b-card-text class="mb-2 mt-3 text-secondary">{{getAcreage}}</b-card-text>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4" lg="3" md="4" sm="4">
                                <b-card-text class="mb-2 mt-3">{{$t('organisation.type')}}:</b-card-text>
                            </b-col>
                            <b-col cols="8" lg="9" md="8" sm="8">
                                <b-card-text class="mb-2 mt-3 text-secondary">{{getTypes}}</b-card-text>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4" lg="3" md="4" sm="4">
                                <b-card-text class="mb-2 mt-3">{{$t('organisation.farm_tourism')}}:</b-card-text>
                            </b-col>
                            <b-col cols="8" lg="9" md="8" sm="8">
                                <b-card-text class="mb-2 mt-3 text-secondary">{{isTourism}}</b-card-text>
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!--  Tabs  -->
        <b-row>
            <b-col>
                <b-card class="mb-2" no-body>
                    <b-tabs card fill pills>
                        <!-- tab introduction -->
                        <b-tab class="provider-tabs">
                            <template #title>
                                <b-icon-house class="mr-1"></b-icon-house>
                                {{$t('organisation.tab.description')}}
                            </template>
                            <p class="p-0" v-html="getIntroduction">
                            </p>
                        </b-tab>
                        <!-- tab certificates -->
                        <b-tab class="provider-tabs">
                            <template #title>
                                <b-icon-file-medical class="mr-1"></b-icon-file-medical>
                                {{$t('organisation.tab.certificate')}}
                            </template>
                            <b-table
                                id="main-table"
                                :fields="tableCertificates.fields"
                                :items="getCertificateItems"
                                bordered
                                head-row-variant="primary"
                                hover
                                outlined
                                sticky-header="330px"
                                table-class="text-center"
                            ></b-table>
                        </b-tab>
                        <!-- tab products -->
<!--                        <b-tab class="provider-tabs">-->
<!--                            <template #title>-->
<!--                                <b-icon-flower3 class="mr-1"></b-icon-flower3>-->
<!--                                {{$t('organisation.tab.products')}}-->
<!--                            </template>-->
<!--                            <p class="p-0">Kmetija</p>-->
<!--                        </b-tab>-->
                        <!-- tab tourism -->
                        <!--<b-tab class="provider-tabs">
                            <template #title>
                                <b-icon-camera class="mr-1"></b-icon-camera>
                                {{$t('organisation.tab.tourism')}}
                            </template>
                            <p class="p-0">Kmetija</p>
                        </b-tab>-->
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
        <!-- products -->
<!--        <b-row>-->
<!--            <b-col lg="12" xl="12">-->
<!--                <b-row class="ml-2">-->
<!--                    <div class="text-secondary font-weight-bold">-->
<!--                        <b-button-->
<!--                            size="sm"-->
<!--                        >-->
<!--                            <b-icon-flower3 class="mr-0" variant="primary"></b-icon-flower3>-->
<!--                            Prikaži izdelke     &lt;!&ndash; // TODO localize or delete&ndash;&gt;-->
<!--                        </b-button>-->
<!--                    </div>-->
<!--                </b-row>-->
<!--            </b-col>-->

<!--        </b-row>-->
    </div>
</template>

<script>
export default {
    name: "ModalSupplier",
    components: {},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        id: {
            type: Number,
            default(){
                return 0;
            }
        }
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            record: {},
            data: null,
            slide: 0,
            sliding: null,
            tableCertificates: {
                items: [],
                fields: [
                    {
                        key: 'organisation',
                        label: this.$t('organisation.certificate_organisation'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'name',
                        label: this.$t('organisation.certificate_name'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'number',
                        label: this.$t('organisation.certificate_number'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'expires',
                        label: this.$t('organisation.certificate_expires'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },
                    {
                        key: 'tag_id',
                        label: this.$t('form.type'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },

                ],
            },
        }
    },
    mounted() {
        // <-- api calls -->
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
        // <-- base -->
        baseSelect: function () {
            this.showOverlay(true);
            this.axios.get(this.uri.main + `/${this.id}`)
                .then((response) => {
                    this.$set(this, 'record', response.data.record);
                    this.$set(this, 'data', response.data.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getCarouselPictures() {
            if (this.__isNull(this.record.pictures_order) || this.record.pictures_order.length === 0 || this.record.pictures_order === undefined) {
                return [
                    {
                        caption: null,
                        src: this.__getDefaultImage
                    }
                ];
            }
            let carousel = [];
            // <-- src -->
            let caption = null;
            this.record.pictures_order.forEach((value, index) => {
                if (value.publish) {
                    this.record.pictures.find((picture) => {
                        if (picture.key === value.key) {
                            caption = picture.meta.name;
                            // <-- locale -->
                            if (picture.locale !== null && picture.locale[this.$i18n.locale] !== undefined) {
                                if (!this.__isNull(picture.locale[this.$i18n.locale].name)) {
                                    caption = picture.locale[this.$i18n.locale].name;
                                }
                            }
                            carousel.push(
                                {
                                    caption: caption,
                                    src: this.__getImageSrc(picture.image.src)
                                }
                            );
                        }
                    });
                }
            });
            // <--  -->
            return carousel;
        },
        getOrgGroup() {
            if (this.__isNull(this.record.org_type) || this.__isNull(this.data.groups)) {
                return null;
            }
            // <--  -->
            let group_id = this.record.org_type.org_group_id;
            let group = null;
            // <--  -->
            this.data.groups.find((n) => {
                if (n.id === group_id) {
                    group = n.meta.name;
                    // <-- locale -->
                    if (n.locale[this.$i18n.locale] !== undefined) {
                        if (!this.__isNull(n.locale[this.$i18n.locale].name)) {
                            group = n.locale[this.$i18n.locale].name;
                        }
                    }
                }
            });
            // <--  -->
            return group;
        },
        getOrgName() {
            if (this.__isNull(this.record.name)) {
                return null;
            }
            let value = this.__isNull(this.record.name.name) ? this.record.name.full_name : this.record.name.name;
            return value;
        },
        getCity() {
            if (this.__isNull(this.record.post_office)) {
                return null;
            }
            // <--  -->
            let value = this.record.post_office.city;
            return value;
        },
        getRegion() {
            if (this.__isNull(this.record.region)) {
                return null;
            }
            // <--  -->
            let value = this.record.region.meta.name;
            return value;
        },
        getAcreage() {
            if (this.__isNull(this.record.farm) || this.__isNull(this.record.farm.acreage)) {
                return null;
            }
            // <--  -->
            let acreage = this.record.farm.acreage;
            acreage = acreage.toLocaleString(this.__getVueLocale());
            // <--  -->
            return acreage + ' ' + this.record.farm.acreage_unit;
        },
        getTypes() {
            if (this.__isNull(this.record.certificates)) {
                return null;
            }
            // <--  -->
            let array = [];
            let temp = '';
            this.record.certificates.forEach(cert => {
                if (!this.__isNull(cert.tag)) {
                    temp = cert.tag.meta.organisation
                    // <-- locale -->
                    if (cert.tag.locale[this.$i18n.locale] !== undefined) {
                        if (!this.__isNull(cert.tag.locale[this.$i18n.locale].organisation)) {
                            temp = cert.tag.locale[this.$i18n.locale].organisation;
                        }
                    }
                    array.push(temp);
                }
            });
            // <--  -->
            return array.join(', ');
        },
        isTourism() {
            if (this.__isNull(this.record.certificates)) {
                return this.$t('form.no');
            }
            // <--  -->
            let isTourism = this.record.farm.is_tourism;
            // <--  -->
            return isTourism ? this.$t('form.yes') : this.$t('form.no');
        },
        getIntroduction() {
            if (this.__isNull(this.record.locale)) {
                return null;
            }
            // <-- locale -->
            let locale = this.record.locale;
            let introduction = null;
            if (locale[this.$i18n.locale] !== undefined) {
                if (!this.__isNull(locale[this.$i18n.locale].introduction)) {
                    introduction = locale[this.$i18n.locale].introduction;
                }
            }
            // <--  -->
            return introduction;
        },
        getCertificateItems() {
            if (this.__isNull(this.record.certificates)) {
                return null;
            }
            // <-- set items -->
            let items = [];
            let tag;
            this.record.certificates.forEach(item => {
                if (item.tag !== null) {
                    // <-- tag -->
                    tag = item.tag.meta.name;
                    // <-- locale -->
                    if (item.tag.locale[this.$i18n.locale] !== undefined) {
                        if (!this.__isNull(item.tag.locale[this.$i18n.locale].name)) {
                            tag = item.tag.locale[this.$i18n.locale].name;
                        }
                    }
                    items.push(
                        {
                            // key: this.__createUniqueKey(index),
                            // index: index,
                            // id: item.id,
                            // state_id: item.state_id,
                            organisation: item.org_name,
                            number: item.number,
                            name: item.name,
                            expires: item.expires,
                            tag_id: tag,
                        }
                    );
                }
            });
            // <--  -->
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
