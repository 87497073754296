<template>
    <div>
        <b-card :header="getName"
                :img-src="getPictureSrc"
                class="text-center shadow m-0 mb-2 ml-2"
                footer-bg-variant="primary"
                header-bg-variant="info"
                header-class="font-weight-bold"
                header-text-variant=""
                img-height="150px"
                img-top
                no-body
                style="min-width: 250px; max-width: 250px;"
        >
            <!-- body -->
            <b-card-body class="px-3 pt-3 pb-0">
                <!-- organisation -->
                <b-card-title class="font-weight-bold text-center pb-0"
                              style="font-size: 1rem">
                    <a class="text-secondary" href="#" @click="moreOrganisation">{{getOrganisation.name}}</a>
                </b-card-title>
                <!-- sale -->
                <b-form-group>
                    <b-row>
                        <b-col cols="auto">
                            <b-form-select
                                id="product_packaging"
                                v-model="record.sale"
                                :options="getSale"
                                class="mb-2 mr-sm-2 mb-sm-0 bg-white"
                                size="md"
                            ></b-form-select>
                        </b-col>
                    </b-row>
                    <!-- quantity -->
                    <b-row class="mt-2" style="font-size: 100%">
                        <b-col cols="auto">
                            <b-form-spinbutton
                                id="product_quantity"
                                v-model="record.quantity"
                                min="1"
                                size="sm"
                            ></b-form-spinbutton>
                        </b-col>
                        <!-- price -->
                        <b-col class="pt-1" cols="auto">
                            <p v-html="$n(getBuyerPrice, 'currency', __getVueCurrencyLocale)"></p>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-card-body>
            <!-- footer -->
            <template #footer>
                <b-row>
                    <!-- button more -->
                    <b-col cols="auto">
                        <b-button
                            variant="info"
                            @click="more()"
                        >
                            {{$t('form.more')}}
                        </b-button>
                    </b-col>
                    <!-- button cart -->
                    <b-col class="ml-auto" cols="auto">
                        <b-overlay
                            :opacity="overlay.opacity"
                            :show="overlay.visible"
                            class="d-inline-block"
                            rounded
                            spinner-small
                            spinner-variant="secondary"
                        >
                            <b-button
                                :variant="getCartVariant"
                                @click="addToCart(_record.id)"
                                :title="isCartDisabled ? $t('cart.noLogin_message') : null"
                            >
                                <!--                                :disabled="isCartDisabled"-->
                                <b-icon-basket/>
                            </b-button>
                        </b-overlay>
                    </b-col>
                </b-row>
            </template>
        </b-card>
        <!-- toast -->
        <b-toast :id="'cart-warning_' + _record.id"
                 :title="$t('form.warning')"
                 variant="danger"
        >
            {{$t('cart.noLogin_message')}}
        </b-toast>
        <!-- modal -->
        <b-modal :id="'modal-product_' + _record.id"
                 :ok-title="$t('form.close')"
                 :title="getName"
                 footer-bg-variant="secondary"
                 header-bg-variant="secondary"
                 header-text-variant="white"
                 ok-only
                 ok-variant="primary"
                 scrollable
                 size="xl"
                 title-class="font-weight-bold"
                 variant="secondary"
        >
            <ModalProduct
                :id="_record.id"
                :uri="uri"
            ></ModalProduct>
        </b-modal>
        <!-- modal supplier -->
        <b-modal :id="'modal-supplier_' + _record.id"
                 :ok-title="$t('form.close')"
                 :title="getOrganisation.name"
                 footer-bg-variant="secondary"
                 header-bg-variant="secondary"
                 header-text-variant="white"
                 ok-only
                 ok-variant="primary"
                 scrollable
                 size="xl"
                 title-class="font-weight-bold"
                 variant="secondary"
        >
            <ModalSupplier
                :id="getOrganisation.id"
                :uri="{main: uri.organisation}"
            ></ModalSupplier>
        </b-modal>
    </div>
</template>

<script>
import ModalProduct from "@/components/_public/_product/ModalProduct";
import ModalSupplier from "@/components/_public/supplier/ModalSupplier";

export default {
    name: "Product",
    components: {ModalSupplier, ModalProduct},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        _record: {
            type: Object,
            default() {
                return {}
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            record: {
                sale: 0,
                quantity: 1,
            },
            errors: {
                addToCart: false,
            }
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        more() {
            this.$bvModal.show('modal-product_' + this._record.id);
        },
        moreOrganisation() {
            this.$bvModal.show('modal-supplier_' + this._record.id);
        },
        addToCart(id) {
            if (this.isCartDisabled) {
                this.$bvToast.show('cart-warning_' + this._record.id);
                return;
            }
            // <--  -->
            this.errors.addToCart = false;
            this.showOverlay(true);
            let cartRecord = this.record;
            cartRecord.product_id = this._record.id;
            // <-- data -->
            let data = {
                'locale': this.$i18n.locale,
                'cartRecord': cartRecord,
            };
            // <-- axios -->
            this.axios.post(this.uri.carts, data, this.__config)
                .then((response) => {
                    // <-- cart count -->
                    this.$root.mx_cartCount = response.data;
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // errors = errors.response.data;
                    // this.errors.message = errors;
                    this.errors.addToCart = true;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        getPictureSrc: function () {
            if (this.__isNull(this._record.pictures_order) || this._record.pictures_order.length === 0 || this._record.pictures_order[0] === undefined) {
                return this.__getDefaultImage;
            }
            // <-- key -->
            let key = null;
            this._record.pictures_order.forEach((value, index) => {
                if (key === null && value.publish) {
                    key = value.key;
                }
            });
            if (key === null) {
                return null;
            }
            // <-- src -->
            let src = null;
            this._record.pictures.find((picture) => {
                if (picture.key === key) {
                    src = picture.image.src;
                }
            });
            // <--  -->
            return this.__getImageSrc(src);
        },
        getName() {
            if (this.__isNull(this._record)) {
                return null;
            }
            // <--  -->
            let _record = this._record;
            // <--  -->
            let name = _record.meta.name;
            let localeTemp = this.__getJsonLocaleValue(_record.locale, 'name')
            name = localeTemp === null ? name : localeTemp;
            // <--  -->
            return name;
        },
        getOrganisation() {
            if (this.__isNull(this._record.organisation)) {
                return null;
            }
            // <--  -->
            let org = this._record.organisation;

            let name = this.__isNull(org.name.name) ? org.name.full_name : org.name.name;
            // let value = this.__isNull(this._record.name.name) ? this._record.name.full_name : this._record.name.name;
            // <--  -->
            return {
                id: org.id,
                name: name,
            }
        },
        getSale() {
            let sales = this._record.sale;
            // <--  -->
            let array = [];
            let quantity, unit, packaging, rpt, currency, ppi;
            let localeTemp;
            sales.forEach((sale, index) => {
                currency = this.__getTenantCurrency;
                quantity = this.__getDecimalLocale(sale.quantity);
                rpt = this.__getDecimalLocale(sale.rpt) + ' ' + currency;

                unit = sale.unit.meta.mark;
                localeTemp = this.__getJsonLocaleValue(sale.unit.locale, 'mark');
                unit = localeTemp === null ? mark : localeTemp;

                packaging = sale.packaging.meta.name;
                localeTemp = this.__getJsonLocaleValue(sale.packaging.locale, 'name');
                packaging = localeTemp === null ? name : localeTemp;

                ppi = this.__getDecimalLocale(sale.ppi);
                ppi += ' ' + currency + '/' + unit;

                array.push(
                    {
                        value: index,
                        text: `${quantity} ${unit} ${packaging} ${rpt} (${ppi})`,
                        ppi: sale.ppi,
                        rp: sale.rp,
                        rpt: sale.rpt,
                    }
                );
            });
            // <--  -->
            return array;
        },
        getBuyerPrice() {
            let rpt = this.getSale[this.record.sale].rpt;
            let quantity = this.record.quantity;
            // <--  -->
            return quantity * rpt;
        },
        // <-- cart -->
        isCartDisabled() {
            return !this.$root.mx_isLogin;
        },
        getCartVariant() {
            if (this.isCartDisabled) {
                return 'light';
            }
            return this.errors.addToCart ? 'danger' : 'secondary';
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.card-img-top {
    width: 100%;
    object-fit: cover;
}
</style>
